@media print {
  #displayBlackInPrintMode {
    color: black;
  }
  #pageBreakInPrintMode {
    page-break-before: always;
    margin-top: 12px;
  }
  button {
    display: none;
  }
}
